import React , { useEffect } from "react";
import Layout from "../components/layout";
import Testimonail from "../components/testimonial";
import { Helmet } from "react-helmet";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function WebsiteBuilding() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          OIC Website Builder | Build A Perfect Website With OIC Website Builder
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="OIC Website Builder | Build A Perfect Website With OIC Website Builder"
        />
        <meta
          name="description"
          content="Create a mobile-friendly site with OIC website builder. Each template is a ready-made design for the optical industry with inbuilt content that can be customized."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="Create a mobile-friendly site with OIC website builder. Each template is a ready-made design for the optical industry with inbuilt content that can be customized."
        />
        <script
          src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
          integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
          integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
          crossorigin="anonymous"
          defer
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
          integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
          crossorigin="anonymous"
          defer
        ></script>
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/website-building-banner.jpg")`,
          }}
        >
          <img src="" alt="Multiple Website themes with OIC Website builder" className="altImage" />
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  IT ALL STARTS WITH <span>THE RIGHT WEBSITE.</span>
                  <br /> GET YOURS TODAY AT <span>THE OIC PLATFORM.</span>
                </h1>
                <a href="https://website-builder.oicapps.com/" target="_blank" className="buttonStyleWhite hvr-forward">
                  Start Free Demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container" data-aos="fade-right" data-aos-duration="400">>
            <div className="row">
              {" "}
              <div className="col-md-2"></div>
              <div className="col-md-8 text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    WEBSITE BUILDING
                  </span>
                </h2>
                <p>
                  Create your unique website effortlessly to impress and engage
                  customers. Building your website with a website builder is
                  much easier if you can start with a template you like to
                  enrich the store performance.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container" data-aos="fade-left" data-aos-duration="400">>
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>
              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglassesW"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition" style={{marginTop:"-2%"}}>
                      <img className="transitionWebImages" alt="Select Your Theme"></img>
                      <p className="transitionWebText">
                        {/* <strong>Select Your Theme</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc featureMarginTextCorrection">
                      Pick the template of your choice create stuning web page
                      with super cool features
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition" style={{marginTop:"-2%"}}>
                      <img className="transitionWebImages transitionDirection" alt="Quicker Decisions" ></img>
                      <p className="transitionWebText transitionDirection">
                        {/* <strong>Quicker Decisions</strong> */}
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      Pick the template of your choice create stuning web page
                      with super cool features
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <div className="padding60 blueBackground">
          <div className="container-fluid" data-aos="fade-right" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-2 text-center"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">
                  Select Your Theme
                </h2>
                <p>
                  Create awesome responsive websites. Easy & fast - No coding!
                  Templates specially designed for optical shops.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>

              <div className="col-md-6 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-01.png"
                  className="img-responsive"
                  alt="Website template list in a view"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 ">
          <div className="container" data-aos="fade-left" data-aos-duration="400">>
            <div className="row ">
              <div className="col-md-12  text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    Multi Themes
                  </span>
                </h2>
              </div>
              <div className="col-md-12 text-center">
                <p>
                  OiC offers numerous theme selections for various pages on the
                  website.
                </p>

                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-02.png"
                  className="img-responsive"
                  alt="Website themes displayed on a laptop "
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <section className="container-fluid noPadding" data-aos="fade-right" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-01.png"
                  className="img-responsive"
                  alt="A person adding stocks to the website"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">Add Stocks</h2>
                <p>
                  The retailer can update the inventory available in store to
                  display for the customer online.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/wholesaler-01.png"
                  className="img-responsive"
                  alt="A person adding stocks to the website"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <div className="padding60">
          <div className="container-fluid" data-aos="fade-left" data-aos-duration="400">>
            <div className="row ContentAligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">
                  Publish The Website
                </h2>
                <p>
                  As soon as the whole thing is over, the retailer can put up
                  the website so that it would be seen by clients.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-5">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-03.png"
                  className="img-responsive"
                  alt="The website option shown on a laptop"
                ></img>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>

        <div className="padding60 blueBackground">
          <section className="container-fluid" data-aos="fade-right" data-aos-duration="400">>
            <div className="row ContentAligner">
              <div className="col-md-2"></div>
              <div className="col-md-4 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-04.png"
                  className="img-responsive"
                  alt="Share the URL option"
                ></img>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">Share URL</h2>
                <p>
                  Retailers can share the website URL with customers which
                  facilitates the clients to view the product available.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-5 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-04.png"
                  className="img-responsive"
                  alt="Share the URL option"
                ></img>
              </div>
            </div>
          </section>
        </div>
        <div className="padding60">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">
                  Interested Products
                </h2>
                <p>
                  The client can pick the involved products from the inventories
                  and take it to the subsequent stage of buy.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-6 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-05.png"
                  className="img-responsive"
                  alt="The customer selects the interested products"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <div className="padding60 blueBackground">
          <section className="container-fluid" data-aos="fade-right" data-aos-duration="400">>
            <div className="row aligner">
              <div className="col-md-7 noPadding DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-06.png"
                  className="img-responsive"
                  alt="The retailer showing the products to the customer"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">
                  Store (or) Home Visit
                </h2>
                <p>
                  The purchaser's interested product can be successfully
                  converted to sale both through inviting them to the store or
                  by means of booking their appointment at their doorstep.
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7 noPadding mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/web-building-06.png"
                  className="img-responsive"
                  alt="The retailer showing the products to the customer"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <Testimonail />
      </Layout>
    </div>
  );
}
